.switch-container {
	width: 60px;
	height: 25px;
	border-radius: 12.5px;
	border: 1px solid #0ac5bf;
	padding: 2px;
	overflow: hidden;
	align-self: center;
	margin: 5px 0;
	cursor: pointer;
}

.switch-background {
	width: 100%;
	height: 100%;
	border-radius: 11.5px;
}

.switch-circle {
	height: 19px;
	width: 19px;
	border-radius: 11.5px;
	background-color: #0ac5bf;
	position: absolute;
}

.groups-tab{
	display: flex;
	justify-content:center;
	cursor: pointer;
	border-right: 1px solid #0ac5bf;
}
.groups-tab div{
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: bold;
}
.groups-tab:hover, .alt-groups-tab:hover{
	background-color: #0ac5bf !important;
	color: white !important;
}

.alt-groups-tab{
	display: flex;
	justify-content:center;
	cursor: pointer;
	border-right: 1px solid #0ac5bf;
}

.alt-groups-tab div{
	margin-top: 7px;
	margin-bottom: 7px;
	font-size: 11px;
}

.group-header {
	border: 1px solid #0ac5bf;
	padding: 0 !important;
	margin-top: 10px !important;
	overflow: hidden !important;
}

.cover-image {
	background-color: #0ac5bf;
	min-height: 250px !important;
}

.group-profile {
	text-align: center;
}

.group-page-title, .group-privacy-status, .group-description {
	line-height: 2em;
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 0;
	margin-bottom: 0;
}

.group-page-title {
	font-size: 19px;
	font-weight: bold;
}

.group-privacy-status {
	font-size: 11px;
}

.group-description {
	font-size: 12px;
}

.join-group-btn {
	background-color: #0ac5bf !important;
	color: white !important;
	border: 1px solid #0ac5bf !important;
	max-width: 110px !important;
	font-size: 11px !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.join-group-btn:active, .join-group-btn:hover {
	background-color: #0ac5bf !important;
	color: white !important;
	border: 1px solid #0ac5bf !important;
}
.group-profile-option-btn, .group-profile-option-btn:hover, .group-profile-option-btn:active {
	border-radius: 50% !important;
	background-color: transparent !important;
	color: white !important;
	border: 2px solid white !important;
	height: 44px;
	width: 44px;
	margin-left: 5px;
	padding: 0;
	position: absolute;
	top: 5px;
	right: 5px;
}

.bottom-border {
	border-bottom: 1px solid #0ac5bf;
}

.no-border-right {
	border-right: 0px transparent white !important;
}


