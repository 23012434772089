.custom-datepicker {
    width: 100% !important;
}
.react-datepicker{
    width: 100% !important;
    border: 1px solid #0ac5bf !important;
}
.react-datepicker__month-container {
    width: 100% !important;
}
.react-datepicker__day-name {
    font-weight: bold !important;
}
.react-datepicker__day-name, .react-datepicker__day {
    font-size: 16px !important;
    color: #033b39 !important;
}
.react-datepicker__day:hover {
    background-color: #b5edeb !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    padding: 3px 10px !important;
    width: 2.4rem !important;
}
.react-datepicker__header {
    background-color: transparent !important;
    border-bottom: 1px solid #0ac5bf !important;
}

.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--in-range{
    background-color: #0ac5bf !important;
    color: #033b39 !important;
}

.react-datepicker__day--selected:hover {
    background-color: #0ac5bf !important;
}